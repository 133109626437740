.contract-wrapper h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
}

.contract-wrapper h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.contract-wrapper p {
  font-size: 16px;
  margin-bottom: 10px;
}

.contract-wrapper a {
  color: #0845dd;
}

.contract-wrapper ol,
.contract-wrapper ul {
  padding-left: 20px;
}

.contract-wrapper ol li,
.contract-wrapper ul li {
  margin-bottom: 10px;
}

table.print-detailing,
table.print-detailing th,
table.print-detailing td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

table.print-detailing {
  width: 100%;
}

table.print-detailing th {
  font-weight: normal;
  font-size: var(--chakra-fontSizes-xs);
  font-family: var(--chakra-fonts-body);
  text-align: left;
}

table.print-detailing td {
  font-size: var(--chakra-fontSizes-xs);
  font-weight: var(--chakra-fontWeights-semibold);
}
